.bottom-blur {
    -webkit-mask-image: linear-gradient(to bottom,black 60%,transparent 80%);
    mask-image: linear-gradient(to bottom,black 60%,transparent 100%);
    /* background: linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) !important; Dégradé semi-transparent avec superposition d'une image */
    /* background-size: cover !important; Ajuste la taille de l'image de fond */
    /* backdrop-filter: blur(10px) !important;  */
    background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%) !important;
    /* z-index: 999; */
 }
.container {
    position: relative;
    /* background-color: rebeccapurple; */
  }
.sticky {
    position: sticky;
    /* background-color: red; */
    top: 50px;
    /* height: fit-content; */
  }
  
  .animated-item {
    transition: transform 0.5s ease;
    /* transform: translateX(200%); */
}
  .animated-item-slideFromBottom {
    transition: transform 0.5s ease;
    /* transform: translateX(200%); */
    transform: translateY(200%);
    opacity: 0;
}
  .animated-item-slideFromTop {
    transition: transform 0.5s ease;
    /* transform: translateX(200%); */
    transform: translateY(-200%);
    opacity: 0;
}
  .animated-item-slideFromRight {
    transition: transform 0.5s ease;
    /* transform: translateX(200%); */
    transform: translateX(200%);
    opacity: 0;
}
  .animated-item-slideFromLeft {
    transition: transform 0.5s ease;
    /* transform: translateX(200%); */
    transform: translateX(-200%);
    opacity: 0;
}
  
  .animated-item.fadeIn {
    animation: fadeIn 1s ease-in-out;
  }
  .animated-item.slideFromBottom {
    animation: slideFromBottom 1s forwards;
  }
  .animated-item.slideFromTop {
    animation: slideFromTop 1s forwards;
  }
  .animated-item.slideFromRight {
    animation: slideFromRight 1s forwards;
  }
  .animated-item.slideFromRightHide {
    animation: slideFromRightHide 1s forwards;
  }
  .animated-item.slideFromLeft {
    animation: slideFromLeft 1s forwards;
  }
  
  @keyframes fadeIn {
    from {
        transform: scale(0);
    }
    80% {
        transform: scale(1.2);
    }
    to {
        transform: scale(1);
    }
  }
  @keyframes slideFromBottom {
    from {
      transform: translateY(200%);
      opacity: 0;
    }
    30% {
        opacity: 0;
    }
    to {
      transform: translateY(0%);
      opacity: 1;
    }
  }
  @keyframes slideFromTop {
    from {
      transform: translateY(-200%);
      opacity: 0;
    }
    30% {
        opacity: 0;
    }
    to {
      transform: translateY(-0%);
      opacity: 1;
    }
  }
  @keyframes slideFromRight {
    from {
      transform: translateX(200%);
      opacity: 0;
    }
    30% {
        opacity: 0;
    }
    to {
      transform: translateX(-0%);
      opacity: 1;
    }
  }
  @keyframes slideFromRightHide {
    from {
      transform: translateX(0%);
    }
    to {
      transform: translateX(200%);
    }
  }
  @keyframes slideFromLeft {
    from {
        transform: translateX(-200%);
        opacity: 0;
      }
      30% {
          opacity: 0;
      }
      to {
        transform: translateX(-0%);
        opacity: 1;
      }
  }